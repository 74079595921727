import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import PrivacityCmp from '../components/privacity';

const Privacity = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Aviso de Privacidad</title>
            <meta name="description" content="Aviso de privacidad de la Comisión de Energía del CCE" />
        </Helmet>
        <main style={{ overflow: 'auto' }}>
            <Header section="" />
            <SubHeader title="Aviso de privacidad" />
            <PrivacityCmp />
            <Footer />
        </main>
    </>
);

export default Privacity;
