import { css } from '@emotion/core';

export default css({
    width: '100%',
    background: '#f4f7f8',
    padding: '50px 0px',
    '& > article': {
        maxWidth: 900,
        margin: '0px auto',
        padding: '45px 0px',
        background: '#fff',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
        '& p': {
            lineHeight: '20px',
            marginBottom: 15,
            textAlign: 'justify',
            padding: '0px 50px',
            '& b': {
                fontWeight: 'bold'
            }
        },
        '& a': {
            color: '#f5333f',
            textDecoration: 'none'
        },
    }
});
