import React from 'react';
import { Link } from 'gatsby';

import styles from './styles';

const PrivacityCmp = () => (
    <section css={styles} className="worklines">
        <article>
            <p>
                El Consejo Coordinador Empresarial (CCE), organismo cúpula del sector
                empresarial, con domicilio en Lancaster 15, Col. Juárez, Delegación
                Cuauhtémoc, 06600 México D.F., en cumplimiento a la Ley Federal de
                Protección de Datos Personales en Posesión de los Particulares, pone
                a su disposición el presente <b>Aviso de privacidad</b> a efecto de hacer de
                su conocimiento que los datos personales, incluso aquellos sensibles y
                patrimoniales o financieros, que nos proporcionan serán tratados dentro
                de un marco que garantice su privacidad y el derecho a la autodeterminación
                informativa de las personas.
            </p>
            <p>
                El CCE será el responsable del tratamiento que, con motivo de la relación
                jurídica que haya sido celebrada y para el cumplimiento de la misma, se le dé
                a sus datos personales.
            </p>
            <p>
                Sus datos personales, incluso aquellos sensibles y patrimoniales o financieros,
                serán utilizados para las siguientes finalidades: identificarlo, localizarlo,
                contactarlo, así como para cumplir las obligaciones derivadas de la relación
                celebrada con Usted, así como cualquier otro fin compatible o análogo de los
                aquí establecidos, como enviar por cualquier medio físico, electrónico o
                magnético, incluyendo de manera enunciativa mas no limitativa: invitación a
                reuniones de trabajo, minutas, noticias y anuncios en general relacionados con
                la Institución, campañas publicitarias afines, invitación a eventos. Asimismo,
                que convenido que Usted acepta la transferencia que el CCE pudiera realizar a
                sus Asociados y a Terceros, en cumplimiento de las finalidades anteriormente
                descritas.
            </p>
            <p>
                Con el objeto de limitar el uso y divulgación de sus datos personales,
                mantendremos medidas de seguridad administrativas, técnicas y físicas, para
                evitar su daño, pérdida, alteración, destrucción, uso, acceso o divulgación
                indebida.
            </p>
            <p>
                El CCE no requerirá el consentimiento del Titular, para tratar datos personales
                cuando lo haga con fundamento en lo dispuesto en el artículo 10 y 37 de la Ley.
            </p>
            <p>
                El ejercicio de los derechos de Acceso, Rectificación, Cancelación y Oposición
                respecto de sus datos personales, así como el de revocar el consentimiento para
                su tratamiento, podrá realizarse a través de los procedimientos que hemos
                implementado y que se encuentran a su disposición en la siguiente dirección
                electrónica: <Link to="/" rel="nofollow noopener noreferrer">https://energiacce.org.mx</Link> o
                mediante una comunicación dirigida a nuestro responsable de la protección de
                datos personales en: <a href="mailto:contacto@cce.org.mx" rel="nofollow noopener noreferrer">contacto@cce.org.mx</a>
            </p>
            <p>
                El CCE podrá enmendar o modificar el presente Aviso de Privacidad como estime
                conveniente, por lo que le informará y pondrá a su disposición el Aviso de
                Privacidad actualizado en el sitio web <Link to="/" rel="nofollow noopener noreferrer">https://energiacce.org.mx</Link>
            </p>
            <p>Fecha de actualización: 13 de julio del 2012</p>
        </article>
    </section>
);

export default PrivacityCmp;
